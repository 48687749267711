import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/react-hot-toast/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/src/app/globals.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/slick-carousel/slick/slick.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/frontend_w3era/frontend_w3era/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/components/Canonical.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ApiProvider"] */ "/home/runner/work/frontend_w3era/frontend_w3era/src/helpers/apiContext.tsx");
