"use client";
import { useEffect, createContext, useContext, useState, useCallback } from "react";
import axios from "axios";
import { BASE_URL } from "@/util/api";

// Define the context type
interface ApiContextType {
  basic_details: any;
  openDropdown: any;
  toggleDropdown: any;
  // fetchBasicDetails: (queryParam: string) => Promise<any>;
}

// Create the context
const ApiContext = createContext<ApiContextType | undefined>(undefined);

export const ApiProvider = ({ children }: { children: React.ReactNode }) => {
  const [basic_details, setBasicDetails] = useState<any>(null);
  const [openDropdown, setOpenDropdown] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);


  // Function to toggle dropdown state
  const toggleDropdown = (menu: string) => {
    setOpenDropdown((prev) => (prev === menu ? null : menu));
  };

  // const fetchBasicDetails = async (queryParam: string) => {
  //   if (!queryParam) return null; // Prevent unnecessary API calls
  
  //   try {
  //     const response = await axios.get(`${BASE_URL}basic-details/`, {
  //       params: { query: queryParam },
  //     });
  //     return response.data;
  //   } catch (error: any) {
  //     console.error("Error fetching basic details:", error.message);
  //     return null;
  //   }
  // };
  
  const fetchBasicData = async () => {
    if (basic_details) return;
  
    setLoading(true);
    try {
      const response = await axios.get(`${BASE_URL}basic-details/`);
      setBasicDetails(response.data);
    } catch (error: any) {
      console.error("Error fetching basic details:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchBasicData();
  }, []);

  return (
    <ApiContext.Provider value={{ basic_details, openDropdown, toggleDropdown }}>
      {children}
    </ApiContext.Provider>
  );
};

// Custom hook to use ApiContext
export const Useapi = () => {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error("Useapi must be used within an ApiProvider");
  }
  return context;
};
